<template>
  <common-modal @close="$emit('close')" height="h-common-modal">
    <div class="flex h-full flex-col justify-between">
      <div class="flex flex-col space-y-6 pb-4 overflow-auto">
        <div
          class="w-1/2 p-2 rounded text-sm text-center"
          :class="computeTextAlign(message)"
          v-for="(message, index) of messages"
          :key="`chat-message-${index}`">
          Message Sent {{ formatMessageSentString(message) }}
        </div>
      </div>
      <div class="w-full text-sm border-t border-b h-16 grid grid-cols-2">
        <div class="text-center pt-2 border-r">
          <p>{{ firstUser.name }}</p>
          <p class="text-grey-500">
            Messages sent: {{ firstUser.messageCount }}
          </p>
        </div>
        <div class="text-center pt-2">
          <p>{{ secondUser.name }}</p>
          <p class="text-grey-500">
            Messages sent: {{ secondUser.messageCount }}
          </p>
        </div>
      </div>
    </div>
  </common-modal>
</template>

<script>
  export default {
    props: ["conversation", "user"],
    data() {
      return {
        firstUser: {
          name: "",
          messageCount: 0,
        },
        secondUser: {
          name: "",
          messageCount: 0,
        },
        messages: [],
      };
    },
    methods: {
      formatMessageSentString(message) {
        if (message.createdDate) {
          const createdAt = new Date(message.createdDate);
          return `${createdAt.toLocaleDateString()} at ${createdAt.toLocaleTimeString(
            [],
            { hour: "2-digit", minute: "2-digit" }
          )}`;
        }

        return "";
      },
      computeTextAlign(message) {
        if (message.authorUserId === this.conversation.prospectID)
          return "self-end bg-blue-100 text-blue";
        return "bg-grey-100 text-grey-500";
      },
    },
    created() {
      this.messages = this.conversation.messages;
      this.firstUser.name = `${this.user.first} ${this.user.last}`;
      this.secondUser.name = this.conversation.prospectName;

      for (let message of this.messages) {
        if (message.authorUserId === this.conversation.prospectID)
          this.secondUser.messageCount += 1;
        else this.firstUser.messageCount += 1;
      }
    },
  };
</script>
